export const environment = {
  appVersion: 'lk_' + require('../../package.json').version + '_develop',
  production: false,
  throttle_limit_percent: 0, // процент трафика уходящих на режим техобслуживания
  throttle_timeout_min: 5, // время в режиме техобслуживания
  SYSTEM_TYPE: 0, // используется при сборке ЛК Природопользователя
  DOMEN_V1: 'lk.v1.develop.rpn.big3.ru',
  DOMEN_CONSTRUCTOR: 'services.develop.rpn.big3.ru',
  SIDENAV_BREAKPOINT: 992,
  ADDRESS_SUGGEST_URL: 'https://suggestions.dadata.ru',
  ADDRESS_DADATA_STAT_URL: 'https://dadata.ru',
  CLEANER_DADATA_URL: 'https://cleaner.dadata.ru',
  ADDRESS_SUGGEST_Authorization: '355f93ce71c996490531a51e9b6d924a54399dd4',
  DADATA_SECRET_KEY: '5856651842bf5936b0f3993943f05844a647a3b8',
  ALERT_MESSAGE: '',
  ALERT_STOP_TIME: '', // '2020-02-20T12:30:00.000Z'
};
